import React from "react"
import {motion} from "framer-motion"


const Spinner = (id) => {
  const [isSpinning, setIsSpinning] = React.useState(false)
return (
  <motion.div
  key={id}
  onMouseEnter={() => setIsSpinning(true)}
  onMouseLeave={() => setIsSpinning(false)}
  animate={{ rotate: isSpinning ? 360 : 0}}
  transition={{ duration: 1 }}
  style={{lineHeight: "16px", margin: "1px"}}
  key={id}>div</motion.div>)
}

const Horde = () => {
  const arrDiv = new Array(2000).fill("")
  return (
  <div style={{display: "flex", flexWrap: "wrap"}}>
  {arrDiv.map((div, id) => <Spinner key={id} id={id} />)}
</div>)

}

export default Horde



/// what i want to happen, is when I mouse over, I want the div to spin.


